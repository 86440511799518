<div class="container" *transloco="let t">
	<div class="header">
		<tg-breadcrumbs></tg-breadcrumbs>

		<div class="header__actions">
			<ng-container *ngIf="productResponse$ | async">
				<button
					*ngIf="!productResponse$.value.isImported"
					tkButton
					theme="secondary"
					(click)="openProduct()"
					[tkTooltip]="t('global.copy_item')"
				>
					<tk-icon icon="copy"></tk-icon>
				</button>
			</ng-container>
			<button tkButton theme="secondary" (click)="copyLink()" [tkTooltip]="t('global.copy_link')">
				<tk-icon icon="link"></tk-icon>
			</button>
			<button
				*ngIf="!productResponse$.value?.isImported"
				tkButton
				theme="secondary"
				(click)="editProduct()"
				tkTooltip="Редактировать товар"
			>
				<tk-icon icon="edit"></tk-icon>
			</button>
			<button tkButton theme="secondary" (click)="deleteProduct()" tkTooltip="Удалить товар">
				<tk-icon icon="trash"></tk-icon>
			</button>
		</div>
	</div>
	<ng-container *ngIf="loading$ | async">
		<tk-loader width="FULL"></tk-loader>
	</ng-container>

	<ng-container *ngIf="productResponse$ | async">
		<h2 class="product__name">{{ productResponse$.value.productName }}</h2>

		<div class="product__container">
			<div class="product__photos">
				<ng-container *ngIf="getCurrentPhotos()?.length > 0">
					<div class="photo__list">
						<button
							class="photo__arrow photo__arrow--up"
							(click)="setActivePhoto(getCurrentPhotos()[getActivePhotoIndex() - 1].file.id)"
							[class.disabled]="getActivePhotoIndex() === 0"
						>
							<tk-icon icon="arrow-down"></tk-icon>
						</button>
						<ng-container *ngFor="let photo of getPhotoPreviewItems()">
							<div
								class="photo__item photo--center"
								[class.active]="photo.file.id === activePhoto"
								(click)="setActivePhoto(photo.file.id)"
							>
								<img [src]="photo.file.id | getFile : 240 | async" alt />
							</div>
						</ng-container>
						<button
							class="photo__arrow"
							(click)="setActivePhoto(getCurrentPhotos()[getActivePhotoIndex() + 1].file.id)"
							[class.disabled]="getActivePhotoIndex() === getCurrentPhotos().length - 1"
						>
							<tk-icon icon="arrow-down"></tk-icon>
						</button>
					</div>
					<div class="photo__main photo--center">
						<img [src]="activePhoto | getFile : 460 | async" alt />
					</div>
				</ng-container>
				<ng-container *ngIf="!(getCurrentPhotos()?.length > 0)">
					<div class="photo__main photo--center">
						<img src="/assets/images/default-img.jpg" alt />
					</div>
				</ng-container>
			</div>

			<div class="info">
				<ng-container
					*ngIf="
						!(
							productResponse$.value.vendorCodes?.length === 1 &&
							productResponse$.value.vendorCodes[0]?.color.colorName === 'По умолчанию'
						) && !productResponse$.value.digitalGoods
					"
				>
					<h3 class="info__head">{{ t('create.colors') }}</h3>
					<div class="info__color">
						<ng-container *ngFor="let color of productResponse$.value.vendorCodes">
							<div
								class="circle"
								[class.active]="color.color.id === activeColor"
								(click)="setActiveColor(color.color.id)"
							>
								<span class="circle__color" [style.background-color]="color.color.rgbCode"></span>
							</div>
						</ng-container>
					</div>
				</ng-container>

				<div class="dimensions">
					<div
						*ngIf="
							productResponse$.value.length ||
							productResponse$.value.width ||
							productResponse$.value.height
						"
					>
						<h3 class="info__head">Габариты</h3>
						<div class="info__text">
							{{ productResponse$.value.length ? productResponse$.value.length : ''
							}}{{
								productResponse$.value.width
									? (productResponse$.value.length ? 'x' : '') + productResponse$.value.width
									: ''
							}}{{ productResponse$.value.height ? 'x' + productResponse$.value.height : '' }}см
						</div>
					</div>
					<div *ngIf="productResponse$.value.weight">
						<h3 class="info__head">Вес</h3>
						<div class="info__text">{{ productResponse$.value.weight }} гр.</div>
					</div>
				</div>

				<h3 class="info__head">{{ t('create.short_desc') }}</h3>
				<div class="info__text" [innerHTML]="productResponse$.value.shortDescription"></div>

				<h3 class="info__head">{{ t('create.detail_desc') }}</h3>
				<div
					class="info__text"
					[ngClass]="{ 'info__text--with-limit': isLimit }"
					[innerHTML]="
						!isReadMore
							? productResponse$.value.fullDescription
							: productResponse$.value.fullDescription?.substring(0, descLimit)
					"
				></div>
				<span>{{ isLimit && isReadMore ? '...' : '' }}</span>
				<button *ngIf="isLimit" class="read-more" type="button" (click)="isReadMore = !isReadMore">
					{{ isReadMore ? 'Подробнее' : 'Скрыть' }}
					<tk-icon icon="arrow-down" size="20" [ngClass]="{ 'arrow--rotate': !isReadMore }"></tk-icon>
				</button>
			</div>
		</div>

		<div class="product__table">
			<h3>{{ t('products.product_items') }}</h3>

			<tk-form-field>
				<tk-label>{{ t('global.search') }}</tk-label>

				<input type="text" tkInput />

				<tk-icon tkSuffix icon="search"></tk-icon>
			</tk-form-field>

			<div class="table-wrap">
				<cdk-table class="table" [dataSource]="positions$">
					<cdk-header-row *cdkHeaderRowDef="columns; sticky: true"></cdk-header-row>

					<ng-container cdkColumnDef="color">
						<cdk-header-cell *cdkHeaderCellDef> {{ t('create.colors') }} </cdk-header-cell>

						<cdk-cell *cdkCellDef="let row">
							{{ row.color?.colorName === 'По умолчанию' ? '-' : row.color?.colorName }}
						</cdk-cell>
					</ng-container>

					<ng-container cdkColumnDef="size">
						<cdk-header-cell *cdkHeaderCellDef> {{ t('create.size') }} </cdk-header-cell>

						<cdk-cell *cdkCellDef="let row">
							{{ row.size?.sizeValue === 'Без размера' ? '-' : row.size?.sizeValue }}
						</cdk-cell>
					</ng-container>

					<ng-container cdkColumnDef="discount">
						<cdk-header-cell *cdkHeaderCellDef> {{ t('create.discount') }} </cdk-header-cell>

						<cdk-cell *cdkCellDef="let row">
							{{ row.discount === 0 ? '-' : row.discount + '%' }}
						</cdk-cell>
					</ng-container>

					<ng-container cdkColumnDef="price">
						<cdk-header-cell *cdkHeaderCellDef> {{ t('create.price') }} </cdk-header-cell>

						<cdk-cell *cdkCellDef="let row">
							<ng-container *ngIf="row.discount === 0"
								>{{ row.price?.toLocaleString() }} {{ shopService.shopMoneySign.value }}</ng-container
							>
							<ng-container *ngIf="row.discount !== 0">
								<span class="discount-price"
									>{{ row.price?.toLocaleString() }} {{ shopService.shopMoneySign.value }}</span
								>
								{{ ' - ' + row.realPrice?.toLocaleString() }} {{ shopService.shopMoneySign.value }}
							</ng-container>
						</cdk-cell>
					</ng-container>

					<ng-container cdkColumnDef="vendor_code">
						<cdk-header-cell *cdkHeaderCellDef> {{ t('create.sku') }} </cdk-header-cell>

						<cdk-cell *cdkCellDef="let row">
							{{ row.vendorCodeValue }}
						</cdk-cell>
					</ng-container>

					<ng-container cdkColumnDef="amount">
						<cdk-header-cell *cdkHeaderCellDef> {{ t('create.quantity') }} </cdk-header-cell>

						<cdk-cell *cdkCellDef="let row">
							{{ row.amount }}
						</cdk-cell>
					</ng-container>

					<cdk-row *cdkRowDef="let row; columns: columns"></cdk-row>
				</cdk-table>
			</div>
		</div>
	</ng-container>
</div>
