<ng-container *transloco="let t; read: 'products'">
	<ng-container *transloco="let tg; read: 'global'">
		<div class="actions">
			<tk-form-field>
				<tk-label>{{ tg('search') }}</tk-label>

				<input type="text" tkInput [formControl]="searchControl" (ngModelChange)="onChangeSearch()" />

				<tk-icon tkSuffix icon="search"></tk-icon>
			</tk-form-field>

			<tk-button-group>
				<tk-button
					tkButton
					class="delete"
					theme="primary"
					size="xl"
					*ngIf="selection.hasValue()"
					(click)="multipleDelete()"
				>
					<tk-icon icon="trash"></tk-icon>
					{{ tg('delete_selected') }}
				</tk-button>

				<a routerLink="create-product" (click)="clickCreateBtn()" tkButton theme="secondary" size="xl">
					<tk-icon icon="plus"></tk-icon>
					{{ t('create_btn') }}
				</a>
			</tk-button-group>
		</div>

		<div class="table-wrap">
			<cdk-table class="table" [dataSource]="(productList$ | async).content">
				<cdk-header-row *cdkHeaderRowDef="columns; sticky: true"></cdk-header-row>

				<ng-container cdkColumnDef="select">
					<cdk-header-cell *cdkHeaderCellDef>
						<tk-checkbox
							[ngModel]="selection.hasValue() && isAllSelected()"
							(ngModelChange)="toggleAllRows()"
						></tk-checkbox>
					</cdk-header-cell>

					<cdk-cell *cdkCellDef="let row">
						<tk-checkbox
							(click)="$event.stopPropagation()"
							(ngModelChange)="select(row)"
							[ngModel]="selection.isSelected(row)"
						>
						</tk-checkbox>
					</cdk-cell>
				</ng-container>
				<ng-container cdkColumnDef="photo">
					<cdk-header-cell *cdkHeaderCellDef> {{ t('photo') }} </cdk-header-cell>

					<cdk-cell *cdkCellDef="let row">
						<img
							[src]="
								row.photo
									? (row.photo?.file.id | getFile : 240 | async)
									: '/assets/images/default-img.jpg'
							"
							[alt]="row.photo ? row.photo?.file.filename : 'Нет фото'"
						/>
					</cdk-cell>
				</ng-container>
				<ng-container cdkColumnDef="productName">
					<cdk-header-cell *cdkHeaderCellDef> {{ t('name') }} </cdk-header-cell>

					<cdk-cell *cdkCellDef="let row">
						{{ row.productName }}
					</cdk-cell>
				</ng-container>
				<ng-container cdkColumnDef="amount">
					<cdk-header-cell *cdkHeaderCellDef> {{ t('quantity') }} </cdk-header-cell>

					<cdk-cell *cdkCellDef="let row">
						{{ row.amount }}
					</cdk-cell>
				</ng-container>
				<ng-container cdkColumnDef="priority">
					<cdk-header-cell *cdkHeaderCellDef> {{ t('priority') }} </cdk-header-cell>

					<cdk-cell *cdkCellDef="let row">
						{{ row.priority }}
					</cdk-cell>
				</ng-container>
				<ng-container cdkColumnDef="actions">
					<cdk-header-cell *cdkHeaderCellDef></cdk-header-cell>

					<cdk-cell *cdkCellDef="let row">
						<button class="options" cdkOverlayOrigin #origin (click)="openMenu(row.productId, $event)">
							<tk-icon icon="options-vertical"></tk-icon>
						</button>

						<ng-template
							cdkConnectedOverlay
							cdkConnectedOverlayHasBackdrop
							[cdkConnectedOverlayOrigin]="origin"
							[cdkConnectedOverlayOpen]="menuIsOpen === row.productId"
							(backdropClick)="closeMenu()"
							cdkConnectedOverlayBackdropClass="backdrop--transparent"
							[cdkConnectedOverlayPositions]="positions"
						>
							<div class="panel">
								<button (click)="copyLink(row.productLink)">{{ tg('copy_link') }}</button>
								<button (click)="copyWebAppLink(row.productId)">{{ tg('copy_webapp_link') }}</button>
								<button *ngIf="!row?.isImported" (click)="copyProduct(row.productId)">
									{{ tg('copy_item') }}
								</button>
								<button (click)="editProduct(row.productId)">
									{{ tg('edit') }}
								</button>
								<button (click)="deleteProduct(row.productId)">{{ tg('delete') }}</button>
							</div>
						</ng-template>
					</cdk-cell>
				</ng-container>

				<cdk-row
					*cdkRowDef="let row; columns: columns"
					class="cdk-row-hover"
					(click)="openProduct(row)"
				></cdk-row>
			</cdk-table>
		</div>

		<tk-paging-controls
			[pages]="pages(productList$ | async)"
			[page]="currentSettings.page ?? 0"
			(amountChange)="setAmount($event)"
			(pageChange)="changePage($event)"
		></tk-paging-controls>
	</ng-container>
</ng-container>
